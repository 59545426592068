<template>
  <v-container>
    <h1 class="text-center mb-5 primary--text">F.A.Q</h1>

    <div v-for="(theme, i) in faq" :key="i">
      <v-subheader class="accent--text font-weight-bold">{{ theme.theme }}</v-subheader>

      <v-expansion-panels class="mb-5">
        <v-expansion-panel v-for="(question, j) in theme.questions" :key="j">
          <v-expansion-panel-header class="font-weight-bold">{{ question.question }} ?</v-expansion-panel-header>
          <v-expansion-panel-content>
            <pre class="format-json">{{ question.answer }}</pre>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <p class="mt-10 text-center font-weight-bold error--text">
      Votre question n'est pas dans cette FAQ ?
    </p>

    <p>
      Dans ce cas, il vous suffit de nous envoyer un mail à alertel@banque-edel.leclerc ou d’utiliser directement le <router-link to="/contact" tag="a" style="cursor: pointer;">Formulaire de Contact</router-link>. Vous pouvez également nous joindre au <span class="primary--text font-weight-medium">05 61 17 34 33</span>. Nous vous répondrons dans les meilleurs délais.
    </p>
    <p>
      Également, vous pouvez consulter la notice utilisateur de l’Extranet dans l’onglet <router-link to="/documentation" tag="a" style="cursor: pointer;">Documentation</router-link>.
    </p>
  </v-container>
</template>

<script>
import faq from "@/assets/faq.json";

export default {
  data: () => ({
    faq: faq,
  }),
};
</script>

<style>
.format-json {
  font: inherit;
  white-space: pre-wrap;
}
</style>
